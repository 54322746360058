import React from "react";
import { Navigate } from "react-router-dom";


// Authentication
const SignIn = React.lazy(() =>
  import("./screens/Authentication/signin/SignIn.js")
);

// Login details
const LoginDetails = React.lazy(
  () => import("./screens/Ems/loginDetails/LoginDetails.js"),
);
const EmailVerification = React.lazy(() => import("./screens/Authentication/signin/EmailVerification.js"));
const Otp = React.lazy(() => import("./screens/Authentication/signin/Otp.js"));
const PasswordAuthentication = React.lazy(() => import("./screens/Authentication/signin/PasswordAuthentication.js"));

const Users = React.lazy(() => import("./screens/Ems/users/Users.js"));
const UserHistoryPage = React.lazy(() => import("./screens/Ems/users/userHistory/UserHistoryPage"))
const UserInfo = React.lazy(() => import("./screens/Ems/users/UserInfo.js"));
const NewUser = React.lazy(() => import("./screens/Ems/users/NewUser.js"));

const OrderStatus = React.lazy(() =>
  import("./screens/booking/OrderStatus/OrderStatus.js")
);
const AddGrStatus = React.lazy(() =>
  import("./screens/booking/OrderStatus/AddOrderGrStatus.js")
);

const DriverPdf = React.lazy(() =>
  import("./screens/Masters/DriverMaster/DriverPdf.js")
);

const Items = React.lazy(() => import("./screens/Masters/Items/Items.js"));
const AddItems = React.lazy(() =>
  import("./screens/Masters/Items/AddItems.js")
);
const ItemsHistoryPage = React.lazy(() =>
  import("./screens/Masters/Items/itemsHistory/ItemsHistoryPage.js")
);

const ResetPassword = React.lazy(() =>
  import("./screens/Authentication/resetpassword/ResetPassword.js")
);


const StationMaster = React.lazy(() =>
  import("./screens/Masters/StationMaster/StationMaster.js")
);

const Locations = React.lazy(() =>
  import("./screens/Masters/locations/Locations.js")
);

const AddLocation = React.lazy(() =>
  import("./screens/Masters/locations/AddLocation.js")
);
const LocationHistoryPage = React.lazy(() =>
  import("./screens/Masters/locations/locationHistory/LocationHistoryPage.js")
);

const CountryHistory = React.lazy(() =>
  import("./screens/Masters/locations/country/CountryHistory")
);

const CountryZoneHistory = React.lazy(() =>
  import("./screens/Masters/locations/countryZone/CountryZoneHistory")
);

const StateHistory = React.lazy(() =>
  import("./screens/Masters/locations/state/StateHistory")
);

const StateZoneHistory = React.lazy(() =>
  import("./screens/Masters/locations/stateZone/StateZoneHistory")
);

const CityHistory = React.lazy(() =>
  import("./screens/Masters/locations/city/CityHistory")
);
const PincodeHistory = React.lazy(() =>
  import("./screens/Masters/locations/pincode/PincodeHistory")
);

const City = React.lazy(() => import("./screens/Masters/locations/City.js"));

const AddCity = React.lazy(() =>
  import("./screens/Masters/locations/AddCity.js")
);

const AddStateZone = React.lazy(() =>
  import("./screens/Masters/locations/AddStateZone.js")
);
const AddState = React.lazy(() =>
  import("./screens/Masters/locations/AddState.js")
);
const AddCountryZone = React.lazy(() =>
  import("./screens/Masters/locations/AddCountryZone.js")
);
const AddCountry = React.lazy(() =>
  import("./screens/Masters/locations/AddCountry.js")
);
const Pincode = React.lazy(() =>
  import("./screens/Masters/locations/Pincode.js")
);

const AddPincode = React.lazy(() =>
  import("./screens/Masters/locations/AddPincode.js")
);

const AddStationMaster = React.lazy(() =>
  import("./screens/Masters/StationMaster/AddStationMaster.js")
);
const BranchStationMaster = React.lazy(() =>
  import("./screens/Masters/BranchStationMaster/BranchStationMaster.js")
);
const BranchHistoryPage = React.lazy(() =>
  import("./screens/Masters/BranchStationMaster/BranchHistoryStationMaster/BranchHistoryPage.js")
);
const AddBranchStation = React.lazy(() =>
  import("./screens/Masters/BranchStationMaster/AddBranchStation.js")
);
const Clients = React.lazy(() => import("./screens/Masters/ClientCustomer/Clients.js"));

const AddClients = React.lazy(() => import("./screens/Masters/ClientCustomer/AddClient.js"));
const ClientHistoryPage = React.lazy(() => import("./screens/Masters/ClientCustomer/clientHistory/ClientHistoryPage.js"));

const PackingMode = React.lazy(() => import("./screens/Masters/PackingMode/PackingMode.js"));
const AddPackingMode = React.lazy(() => import("./screens/Masters/PackingMode/AddPackingMode.js"));
const PackingZoneHistory = React.lazy(() => import("./screens/Masters/PackingMode/packingmodehistory/PackingZoneHistory.js"));

const Vendor = React.lazy(() => import("./screens/Masters/vendor/Vendor.js"));
const UpateCompanyType = React.lazy(() => import("./screens/Masters/vendor/UpadteCompanyType.js"));

const AddVendor = React.lazy(() => import("./screens/Masters/vendor/AddVendor.js"));
const VendorHistoryPage = React.lazy(() => import("./screens/Masters/vendor/vendorHistory/VendorHistoryPage.js"));


//ConnectUs
const RaiseIssue = React.lazy(() => import("./screens/contactUs/RaiseIssue.js"));
const AddRaiseIssue = React.lazy(() => import("./screens/contactUs/AddRaiseIssue.js"));
const IssueReport = React.lazy(() => import("./screens/contactUs/report/IssueReport.js"));
const Demo = React.lazy(() => import("./screens/contactUs/Demo.js"));

const PendingRequest = React.lazy(() => import("./screens/contactUs/PendingRequest.js"));
const CompletedRequest = React.lazy(() => import("./screens/contactUs/CompletedRequest.js"));
const InProgressRequest = React.lazy(() => import("./screens/contactUs/InProgressRequest.js"));
const TodaysRequest = React.lazy(() => import("./screens/contactUs/TodaysRequest.js"));

// main dashboard 
const Dashboard = React.lazy(
  () => import("./screens/Dashboard/Dashboard.js"),
);
// 404
const PageNotFound = React.lazy(
  () => import("./screens/Utility/Page404.js"),
);

// ----------------------------Vehicle Master

//Dashboard

const VmsDashboard = React.lazy(
  () => import("./screens/Dashboard/VmsDashboard.js"),
);

const OrderDetail = React.lazy(() => import("./screens/Dashboard/dashboardtype/OrderDetails"));
const BranchStatusCard = React.lazy(() => import("./screens/Dashboard/dashboardtype/BranchDailyDetails"));


// Profile page is attached here

const Profile = React.lazy(
  () => import("../src/components/profile/UserProfile"),
);


//Vehicle

const Model = React.lazy(() => import("../src/screens/vms/model/Model.js"));
const AddModel = React.lazy(
  () => import("../src/screens/vms/model/AddModel.js"),
);

const Issue = React.lazy(() => import("./screens/vms/Issue/Issue.js"));

const AddIssue = React.lazy(() => import("./screens/vms/Issue/AddIssue.js"));


const Vehicle = React.lazy(() => import("./screens/vms/Vehicle/Vehicle.js"));
const AddVehicle = React.lazy(
  () => import("./screens/vms/Vehicle/AddVehicle.js"),
);
const VehicleHistoryPage = React.lazy(() =>
  import("./screens/Masters/VehicleMaster/VehicleHistoryMaster/VehicleHistoryPage.js")
);

const Reminder = React.lazy(
  () => import("./screens/vms/reminder/Reminder.js"),
);
const AddReminder = React.lazy(
  () => import("./screens/vms/reminder/AddReminder.js"),
);
const ModelTabs = React.lazy(() => import("./screens/vms/model/ModelTabs.js"));
const VehicleTabs = React.lazy(
  () => import("./screens/vms/Vehicle/Tab/VehicleTabs.js"),
);

const JobCard = React.lazy(
  () => import("./screens/vms/jobcard/JobCard.js"),
);
const AddJobCard = React.lazy(
  () => import("./screens/vms/jobcard/AddJobCard.js"),
);

const ServiceCenter = React.lazy(
  () => import("./screens/vms/servicecenter/ServiceCenter.js"),
);
const AddServiceCenter = React.lazy(
  () => import("./screens/vms/servicecenter/AddServiceCenter.js"),
);
// rate master
const FreightMaster = React.lazy(() =>
  import("./screens/Masters/FreightMaster/FreightMaster.js")
);
const AddFreightMaster = React.lazy(() =>
  import("./screens/Masters/FreightMaster/AddFreightMaster.js")
);

const ClientRatePdf = React.lazy(() =>
  import("./screens/Masters/FreightMaster/pdf/ClientRatePdf.js")
);

// Organization

const Organization = React.lazy(() =>
  import("./screens/Organization/organization/Organization.js")
);
const AddOrganization = React.lazy(() =>
  import("./screens/Organization/organization/AddOrganization.js")
);

//organization
const Department = React.lazy(() =>
  import("./screens/Organization/department/Department.js")
);

const AddDepartment = React.lazy(() =>
  import("./screens/Organization/department/AddDepartment.js")
);

const Designation = React.lazy(() =>
  import("./screens/Organization/designation/Designation.js")
);

const AddDesignation = React.lazy(() =>
  import("./screens/Organization/designation/AddDesignation.js")
);


//  Manifests
const PendingForDispatch = React.lazy(() =>
  import("./screens/Manifest/pendingfordispatch/PendingForDispatch.js"),
);
const NewManifestPdf = React.lazy(() =>
  import("./screens/Manifest/pendingfordispatch/NewManifestPdf.js"),
);
const MainManifestPdf = React.lazy(() =>
  import("./data/Manifest/pendingForDepart/MainManifestPdf.js"),

);
const Forwarding = React.lazy(() =>
  import("./screens/Manifest/forwardmanifest/Forwarding.js"),
);
const AddForwarding = React.lazy(() =>
  import("./screens/Manifest/forwardmanifest/AddForward.js"),
);
const ManifestPdf = React.lazy(() =>
  import("./data/Manifest/pendingForDispatch/manifests/ManifestPdf.js"),
);
const IncomingPdf = React.lazy(() =>
  import("./data/Manifest/incomingManifest/IncomingPdf.js"),
);
const UpdateManifest = React.lazy(() =>
  import("./screens/Manifest/updateManifest/UpdateManifest.js"),
);
const PendingDepart = React.lazy(() =>
  import("./screens/Manifest/pendingDepart/PendingDepart.js"),
);
const AddHeadManifest = React.lazy(() =>
  import("./screens/Manifest/headManifest/AddHeadManifest.js"),
);
const PendingDepartVehicle = React.lazy(() =>
  import("./screens/Manifest/pendingDepart/PendingDepartVehicle.js"),
);
const IncomingManifest = React.lazy(() =>
  import("./screens/Manifest/incomingManifest/IncomingManifest.js"),
);
const Pickedup = React.lazy(() =>
  import("./screens/Manifest/incomingVehcile/PickedUp.js"),
);
const EditManifest = React.lazy(() =>
  import("./screens/Manifest/editManifest/EditManifest.js"),
);
const EditRoughDocket = React.lazy(() =>
  import("./screens/Manifest/editManifest/EditRoughDocket.js"),
);

const EditHubDocket = React.lazy(() =>
  import("./screens/Manifest/editHub/EditHubDocket.js"),
);

const AddHubAir = React.lazy(() =>
  import("./screens/Manifest/hubAirForward/AddHubAir.js"),
);

const HubAirForwarding = React.lazy(() =>
  import("./screens/Manifest/hubAirForward/HubAirForwarding.js"),
);

const AddHubVehicle = React.lazy(() =>
  import("./screens/Manifest/hubVehicleForward/AddHubVehicle.js"),
);

const HubVehicleForwarding = React.lazy(() =>
  import("./screens/Manifest/hubVehicleForward/HubVehicleForwarding.js"),
);

const BreakManifest = React.lazy(() =>
  import("./screens/Manifest/recieveManifest/BreakManifest.js"),
);
const BreakHubManifest = React.lazy(() =>
  import("./screens/Manifest/recieveHubManifest/BreakHubManifest.js"),
);
const EditHub = React.lazy(() =>
  import("./screens/Manifest/editHub/EditHub.js"),
);
const ReciveManifest = React.lazy(() =>
  import("./screens/Manifest/recieveManifest/ReciveManifest.js"),
);
const RecieveHubManifest = React.lazy(() =>
  import("./screens/Manifest/recieveHubManifest/ReciveHubManifest.js"),
);
const RecieveHub = React.lazy(() =>
  import("./screens/Manifest/incomingManifest/IncomingHub.js"),
);
const AllManifest = React.lazy(() =>
  import("./screens/Manifest/allmanifest/AllManifest.js"),
);
const RoughManifestPdf = React.lazy(() =>
  import("./data/Manifest/roughManifest/ManifestPdf.js"),
);
const BranchForwarding = React.lazy(() =>
  import("./screens/Manifest/forwardbranchmanifest/BranchForwarding.js"),
);
const PdfBranchForward = React.lazy(() =>
  import("./data/Manifest/branchManifest/BranchManifestPdf.js"),
);
const AddBranchForward = React.lazy(() =>
  import("./screens/Manifest/forwardbranchmanifest/AddBranchForward.js"),
);


// Runsheet
const IncomingRunsheetHub = React.lazy(() =>
  import("./screens/Runsheet/incomingRunsheet/IncomingRunsheetHub.js"),
);
const HubRunsheet = React.lazy(() =>
  import("./screens/Runsheet/HubRunsheet.js"),
);
const AllRunsheet = React.lazy(() =>
  import("./screens/Runsheet/AllRunsheet.js"),
);

const PodUpload = React.lazy(() =>
  import("./screens/Runsheet/podUpload/PodUpload.js")
);
const UpdatePodUploadInfo = React.lazy(() =>
  import("./screens/Runsheet/podUpload/UpdatePodUploadInfo.js")
);
const PendingPod = React.lazy(() =>
  import("./screens/Runsheet/podUpload/PendingPod.js")
);

const DeliveryReceipt = React.lazy(() =>
  import("./screens/Runsheet/deliveryReceipt/DeliveryReceipt.js"),
);
const DeliveryReceiptPdf = React.lazy(() =>
  import("./screens/Runsheet/deliveryReceipt/DeliveryReceiptPdf.js"),
);
const PendingToDelivery = React.lazy(() =>
  import("./screens/Runsheet/pendingToDelivery/PendingToDelivery.js"),
);
const ChangedRusheet = React.lazy(() =>
  import("./screens/Runsheet/ChangedRunsheet.js"),
);

const CreateRunsheet = React.lazy(() =>import("./screens/Runsheet/pendingToDelivery/CreateRunsheet.js"),);

const RunsheetPDF = React.lazy(() =>
  import("./screens/Runsheet/runsheetPdf/RunsheetPDF.js"),
);

const GatePass = React.lazy(() =>
  import("./screens/Runsheet/runsheetPdf/GatePass.js")
);


//Vehicle ms
const VehicleMaster = React.lazy(() =>
  import("./screens/Masters/VehicleMaster/VehicleMaster.js")
);
const AddVehicleMaster = React.lazy(() =>
  import("./screens/Masters/VehicleMaster/Add_Vehicle.js")
);
const DriverMaster = React.lazy(() =>
  import("./screens/Masters/DriverMaster/Driver.js")
);
const AddDriverMaster = React.lazy(() =>
  import("./screens/Masters/DriverMaster/Add_Driver.js")
);
const DriverHistoryPage = React.lazy(() =>
  import("./screens/Masters/DriverMaster/DriverHistoryMaster/DriverHistoryPage.js")
);
const AllManifestHistoryPage = React.lazy(() =>
  import("./screens/Manifest/allmanifest/AllManifestHistory/AllManifestHistoryPage.js")
);



const DocketIssue = React.lazy(() =>
  import("./screens/booking/DocketIssue/DocketIssue.js")
);

const Test = React.lazy(() =>
  import("./components/test/Test.js")
);

//<------------------------------------------Booking Start-------------------------------------------->

const BookingPdf = React.lazy(() => import("./screens/booking/BookingPdf.js"));
const CounterBooking = React.lazy(() =>import("./screens/booking/counterBooking/CounterBooking.js"));
const AddCounterBooking = React.lazy(() => import("./screens/booking/counterBooking/AddCounterBooking.js"));
const CounterBookingHistoryPage = React.lazy(() =>import("./screens/booking/counterBooking/CounterBookingHistory/CounterBookingHistoryPage.js"));

const QuickBooking = React.lazy(() =>import("./screens/booking/quickBooking/QuickBooking.js"));
const AddQuickBooking = React.lazy(() =>import("./screens/booking/quickBooking/AddQuickBooking.js"));
const QuickBookingHistoryPage = React.lazy(() =>import("./screens/booking/quickBooking/QuickBookingHistory/QuickBookingHistoryPage.js"));

const ClientBooking = React.lazy(() =>import("./screens/booking/clientBooking/ClientBooking.js"));
const AddClientBooking = React.lazy(() =>import("./screens/booking/clientBooking/AddClientBooking.js"));
const ClientBookingHistoryPage = React.lazy(() =>import("./screens/booking/clientBooking/ClientBookingHistory/ClientBookingHistoryPage"));

const ThirdPartyBooking = React.lazy(() =>import("./screens/booking/thirdPartyBooking/ThirdPartyBooking.js"));
const AddThirdPartyBooking = React.lazy(() =>import("./screens/booking/thirdPartyBooking/AddThirdPartyBooking.js"));
const ThirdPartyBookingHistoryPage = React.lazy(() =>import("./screens/booking/thirdPartyBooking/ThirdPartyBookingHistory/ThirdPartyBookingHistoryPage"));

//<-------------------------------------------------Booking End---------------------------------------------->


// Trackin Order Page
const TrackingOrder = React.lazy(() =>import("./screens/track/trackingPage/TrackingOrder.js"));

// Analytics
const Report = React.lazy(() => import("./screens/analytic/Reports.js"));

const AllRunsheetHistoryPage = React.lazy(() =>import("./screens/Runsheet/AllRunsheetHistory/AllRunsheetHistoryPage.js"));

const AllRunsheetInvoice = React.lazy(() =>  import("./screens/Runsheet/AllRunsheetInvoice.js"));

const AllManifestInvoice = React.lazy(() =>
  import("./screens/Manifest/allmanifest/manifestInvoice/AllManifestInvoice.js")
);

const MisDetailedReport = React.lazy(() =>
  import("./screens/analytic/misDetailedReport/MisDetailedReport.js")
);

const RunsheetReport = React.lazy(() =>
  import("./screens/analytic/runsheetReport/RunsheetReport.js")
);
const ManifestReport = React.lazy(() =>
  import("./screens/analytic/manifestReport/ManifestReport.js")
);

const auth_routes = [
  { path: "/", element: <SignIn /> },
  { path: "/signin", element: <SignIn /> },
  { path: "/dashboard", element: <Navigate to="/signin" /> },
  // { path: "/booking", element: <Navigate to="/signin" /> },
  { path: "/resetpassword", element: <ResetPassword /> },
  { path: "/newuser", element: <NewUser /> },
  { path: "/track/trackingPage/TrackingOrder", element: <TrackingOrder /> },
  { path: "/booking/bookingpdf", element: <BookingPdf /> },
  { path: "/runsheet/runsheetPdf/RunsheetPDF", element: <RunsheetPDF /> },
  // { path: "/forgetpassword", element: <ForgetPassword /> },
  {
    path: "/emailverification",
    name: "EmailVerification",
    element: <EmailVerification />,
  },
  {
    path: "/otp",
    name: "Otp",
    element: <Otp />,
  },
  {
    path: "/passwordauthentication",
    name: "PasswordAuthentication",
    element: <PasswordAuthentication />,
  },
];

const routes = [
  { path: "/signin", element: <Navigate to="/dashboard" /> },
  { path: "/", element: <Navigate to="/dashboard" /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/newuser", element: <NewUser /> },
  { path: "/404", element: <PageNotFound /> },
  { path: "*", element: <Navigate to="/404" replace /> },
  
  {
    path: "/ems/users",
    element: <Users />,
  },

  {
    path: "/ems/logindetails",
    element: <LoginDetails />,
  },

  {
    path: "/UserHistoryPage",
    element: <UserHistoryPage />
  },

  {
    path: "/ems/users/Userinfo",
    element: <UserInfo />,
  },
  // {
  //   path: "/newuser",
  //   element: <NewUser />,
  // },

  // <-------------------------------------------Booking Start---------------------------------------->

  {path: "/booking/clientbooking",element: <ClientBooking />,},
  {path: "/booking/counterBooking/CounterBookingHistoryPage",element: <CounterBookingHistoryPage />,},
  
  {path: "/booking/addclientbooking",element: <AddClientBooking />,},
  {path: "/booking/addthirdpartybooking",element: <AddThirdPartyBooking />,},
  {path: "/booking/clientBooking/ClientBookingHistoryPage",element: <ClientBookingHistoryPage />,},
  
  { path: "/booking/thirdpartybooking",element: <ThirdPartyBooking />,},
  {path: "/booking/thirdpartyBooking/ThirdPartyBookingHistoryPage",element: <ThirdPartyBookingHistoryPage />,},
  
  {path: "/booking/quickbooking",element: <QuickBooking />,},
  {path: "/booking/addquickbooking",element: <AddQuickBooking />, },
  {path: "/booking/quickbookinglist",element: <QuickBooking />,},
  {path: "/booking/quickBooking/QuickBookingHistoryPage",element: <QuickBookingHistoryPage />,},

  {path: "/booking/orders/addgrstatus", element: <AddGrStatus />,},
  {path: "/booking/bookingpdf", element: <BookingPdf />,},
  {path: "/booking/driverpdf", element: <DriverPdf />, },

  {path: "/booking/bookinglist",name: "CounterBooking",element: <CounterBooking />,},
  {path: "/booking",name: "AddCounterBooking", element: <AddCounterBooking /> },
  {path: "/booking/orders/updatestatus",name: "Order Status",element: <OrderStatus />,},
  

  // <------------------------------------------Booking End------------------------------------------->

  { path: "/runsheet/runsheetPdf/RunsheetPDF", element: <RunsheetPDF /> },

  {
    path: "/master/stationmaster/stationmaster",
    name: "StationMaster",
    element: <StationMaster />,
  },
  {
    path: "/master/stationmaster/add_station",
    name: "AddStationMaster",
    element: <AddStationMaster />,
  },
  {
    path: "/master/locations",
    element: <Locations />,
  },
  {
    path: "/master/locations/addlocation",
    element: <AddLocation />,
  },
  {
    path: "/locations/locationHistory/LocationHistoryPage",
    element: <LocationHistoryPage />,
  },
  {
    path: "/country/CountryHistory",
    element: <CountryHistory />,
  },
  {
    path: "/countryZone/CountryZoneHistory",
    element: <CountryZoneHistory />,
  },
  {
    path: "/state/StateHistory",
    element: <StateHistory />,
  },
  {
    path: "/stateZone/StateZoneHistory",
    element: <StateZoneHistory />,
  },
  {
    path: "/city/CityHistory",
    element: <CityHistory />,
  },
  {
    path: "/pincode/PincodeHistory",
    element: <PincodeHistory />,
  },
  {
    path: "/master/city",
    element: <City />,
  },
  {
    path: "/master/city/AddCity",
    element: <AddCity />,
  },
  {
    path: "/locations/AddStateZone",
    element: <AddStateZone />,
  },
  {
    path: "/locations/AddState",
    element: <AddState />,
  },
  {
    path: "/locations/AddCountryZone",
    element: <AddCountryZone />,
  },
  {
    path: "/locations/AddCountry",
    element: <AddCountry />,
  },
  {
    path: "/master/pincode",
    element: <Pincode />,
  },
  {
    path: "/master/pincode/AddPincode",
    element: <AddPincode />,
  },
  {
    path: "/master/branchstationmaster/branchstationmaster",
    name: "branchstationmaster",
    element: <BranchStationMaster />,
  },
  {
    path: "/BranchHistoryPage",
    name: "branchhistorystationmaster",
    element: <BranchHistoryPage />,
  },
  {
    path: "/master/branchstationmaster/add_branchstationmaster",
    name: "addbranchstationmaster",
    element: <AddBranchStation />,
  },

  {
    path: "/master/items/items",
    name: "Items",
    element: <Items />,
  },
  {
    path: "/master/items/add_items",
    name: "Items",
    element: <AddItems />,
  },
  {
    path: "/master/items/items_history",
    name: "ItemsHistoryPage",
    element: <ItemsHistoryPage />,
  },
  {
    path: "/master/clients/clients",
    name: "Clients",
    element: <Clients />,
  },
  {
    path: "/master/clients/add_clients",
    name: "AddClients",
    element: <AddClients />,
  },
  {
    path: "/ClientHistoryPage",
    name: "clienthistorystationmaster",
    element: <ClientHistoryPage />,
  },
  {
    path: "/master/packing/packing",
    name: "PackingMode",
    element: <PackingMode />,
  },
  {
    path: "/master/packing/add_packing",
    name: "AddPackingMode",
    element: <AddPackingMode />,
  },
  {
    path: "/master/packing/packing_history",
    name: "PackingZoneHistory",
    element: <PackingZoneHistory />,
  },


  {
    path: "/master/vendor/Vendor",
    element: <Vendor />,
  },
  {
    path: "/master/vendor/updatecompanytype",
    element: <UpateCompanyType />,
  },
  {
    path: "/master/vendor/AddVendor",
    element: <AddVendor />,
  },
  {
    path: "/master/vendor/vendorHistory/VendorHistoryPage",
    element: <VendorHistoryPage />,
  },


  // rate master
  {
    path: "/master/freight/addfreight",
    element: <AddFreightMaster />,
  },

  {
    path: "/master/freight",
    element: <FreightMaster />,
  },

  {
    path: "/master/rate/clientratepdf",
    element: <ClientRatePdf />,
  },

  //ConnectUs
  {
    path: "/connectUs/raiseIssue",
    element: <RaiseIssue />,
  },
  {
    path: "/connectUs/addRaiseIssue",
    element: <AddRaiseIssue />,
  },
  {
    path: "/connectUs/IssueReport",
    element: <IssueReport />,
  },
  {
    path: "/connectUs/CompletedRequest",
    element: <CompletedRequest />,
  },
  {
    path: "/connectUs/InProgressRequest",
    element: <InProgressRequest />,
  },
  {
    path: "/connectUs/TodaysRequest",
    element: <TodaysRequest />,
  },
  {
    path: "/connectUs/PendingRequest",
    element: <PendingRequest />,
  },
  {
    path: "/enquiry/status",
    element: <Demo />,
  },

  //veicle model
  //dashboard

  {
    path: "/dashboard",
    element: <Dashboard />,
  },

  {
    path: "/dashboard/VmsDashboard",
    element: <VmsDashboard />,
  },

  {
    path: "/dashboardtype/OrderDetails",
    element: <OrderDetail />,
  },

  {
    path: "/dashboardtype/BranchDailyDetails",
    element: <BranchStatusCard />,
  },

  // ========================profile
  {
    path: "/userProfile/Profile",
    element: <Profile />,
  },

  // vms=============================================
  {
    path: "/vms/Issue/Issue",
    element: <Issue />,
  },
  {
    path: "/vms/vehicle/Vehicle",
    element: <Vehicle />,
  },
  {
    path: "/vms/vehicle/AddVehicle",
    element: <AddVehicle />,
  },
  {
    path: "/Vehicle/VehicleHistory/VehicleHistoryPage",
    element: <VehicleHistoryPage />,
  },

  {
    path: "/vms/reminder/AddReminder",
    element: <AddReminder />,
  },
  {
    path: "/vms/reminder/Reminder",
    element: <Reminder />,
  },
  // {
  //   path: "/Vehicle/Tab/Tabs",
  //   element: <Tabs />,
  // },
  {
    path: "/vms/model/ModelTabs",
    element: <ModelTabs />,
  },
  {
    path: "/Vehicle/Tab/vehicleTabs",
    element: <VehicleTabs />,
  },

  {
    path: "vms/jobcard/JobCard",
    element: <JobCard />,
  },
  {
    path: "vms/jobcard/AddJobCard",
    element: <AddJobCard />,
  },

  {
    path: "vms/servicecenter/ServiceCenter",
    element: <ServiceCenter />,
  },
  {
    path: "vms/servicecenter/AddServiceCenter",
    element: <AddServiceCenter />,
  },
  {
    path: "/vms/issue/Issue",
    element: <Issue />,
  },
  {
    path: "/vms/issue/AddIssue",
    element: <AddIssue />,
  },


  {
    path: "/vms/model/Model",
    element: <Model />,
  },
  {
    path: "/vms/model/AddModel",
    element: <AddModel />,
  },

  {
    path: "/Vehicle/Vehicle",
    element: <Vehicle />,
  },
  {
    path: "/Vehicle/AddVehicle",
    element: <AddVehicle />,
  },

  // Organization
  {
    path: "/organization/organization",
    name: "Organization",
    element: <Organization />,
  },
  {
    path: "/organization/add_organization",
    name: "Organization",
    element: <AddOrganization />,
  },

  //department
  {
    path: "/organization/department/Department",
    name: "Department",
    element: <Department />,
  },
  {
    path: "/organization/department/AddDepartment",
    name: "Department",
    element: <AddDepartment />,
  },

  // designation
  {
    path: "/organization/designation/Designation",
    element: <Designation />,
  },
  {
    path: "/organization/designation/AddDesignation",
    element: <AddDesignation />,
  },

  // Vehicle

  {
    path: "/master/vehicle/vehicle",
    name: "Vehicle Master",
    element: <VehicleMaster />,
  },

  {
    path: "/master/Add_Vehcile",
    name: "AddVehicle Master",
    element: <AddVehicleMaster />,
  },

  {
    path: "/master/driver/driver",
    name: "Driver Master",
    element: <DriverMaster />,
  },
  {
    path: "/master/driver/adddriver",
    name: "AddDriver Master",
    element: <AddDriverMaster />,
  },
  {
    path: "/driver/driverHistory/DriverHistoryPage",
    element: <DriverHistoryPage />,
  },

  // Manifest
  
  {
    path: "/manifest/pendingfordispatch",
    element: <PendingForDispatch />,
  },
  {
    path: "/manifest/manifestpdf",
    element: <ManifestPdf />,
  },
  {
    path: "/manifest/incomingpdf",
    element: <IncomingPdf />,
  },
  {
    path: "/manifest/newmanifestpdf",
    element: <NewManifestPdf />,
  },
  {
    path: "/manifest/mainmanifestPdf",
    element: <MainManifestPdf />,
  },
  {
    path: "/manifest/breakmanifest",
    element: <BreakManifest />,
  },
  {
    path: "/manifest/updatemanifest",
    element: <UpdateManifest />,
  },
  {
    path: "/manifest/breakhubmanifest",
    element: <BreakHubManifest />,
  },
  {
    path: "/manifest/pickeduporders",
    element: <Pickedup />,
  },
  {
    path: "/manifest/roughmanifest",
    element: <Forwarding />,
  },
  {
    path: "/manifest/branchforward",
    element: <AddBranchForward />,
  },
  {
    path: "/manifest/branchmanifest",
    element: <BranchForwarding />,
  },
  {
    path: "/manifest/branch_pdf",
    element: <PdfBranchForward />,
  },
  {
    path: "/manifest/forward",
    element: <AddForwarding />,
  },
  {
    path: "/manifest/manifestPdf",
    element: <ManifestPdf />,
  },
  {
    path: "/manifest/pendingtodepart",
    element: <PendingDepart />,
  },
  {
    path: "/manifest/addheadmanifest",
    element: <AddHeadManifest />,
  },
  {
    path: "/manifest/pendingtodepartvehicle",
    element: <PendingDepartVehicle />,
  },
  {
    path: "/manifest/incomingmanifest",
    element: <IncomingManifest />,
  },
  {
    path: "/manifest/editmanifest",
    element: <EditManifest />,
  },
  {
    path: "/manifest/editraughdocket",
    element: <EditRoughDocket />,
  },
  {
    path: "/manifest/edithubdocket",
    element: <EditHubDocket />,
  },
  {
    path: "/manifest/edithub",
    element: <EditHub />,
  },
  {
    path: "/manifest/recieve_hub_manifest",
    element: <RecieveHubManifest />,
  },
  {
    path: "/manifest/incominghub",
    element: <RecieveHub />,
  },
  {
    path: "/manifest/recieve_manifest",
    element: <ReciveManifest />,
  },
  {
    path: "/manifest/allmanifest",
    element: <AllManifest />,
  },

  {
    path: "/manifest/allmanifest/allManifestHistoryPage",
    element: <AllManifestHistoryPage />,
  },

  {
    path: "/manifest/addhubair",
    element: <AddHubAir />,
  },

  {
    path: "/manifest/hubairforward",
    element: <HubAirForwarding />,
  },
  {
    path: "/manifest/addhubvehicle",
    element: <AddHubVehicle />,
  },
  {
    path: "/manifest/hubvehicleforward",
    element: <HubVehicleForwarding />,
  },

  //Runsheet
  {
    path: "/runsheet/incomingrunsheet",
    element: <IncomingRunsheetHub />,
  },
  {
    path: "/runsheet/hubrunsheet",
    element: <HubRunsheet />,
  },
  {
    path: "/runsheet/podUpload",
    element: <PodUpload />,
  },
  {
    path: "/runsheet/podUpload/UpdatePodUploadInfo",
    element: <UpdatePodUploadInfo />,
  },
  {
    path: "/runsheet/podUpload/PendingPod",
    element: <PendingPod />,
  },
  {
    path: "/runsheet/allrunsheet",
    element: <AllRunsheet />,
  },
  {
    path: "/runsheet/allrunsheet/allRunsheetHistoryPage",
    element: <AllRunsheetHistoryPage />,
  },
  {
    path: "/screens/Runsheet/allRunsheetInvoice",
    element: <AllRunsheetInvoice />,
  },
  {
    path: "/allmanifest/manifestInvoice/allManifestInvoice",
    element: <AllManifestInvoice />,
  },
  {
    path: "/runsheet/deliveryReceipt/DeliveryReceipt",
    element: <DeliveryReceipt />,
  },
  {
    path: "/runsheet/deliveryReceipt/DeliveryReceiptPdf",
    element: <DeliveryReceiptPdf />,
  },
  {
    path: "/runsheet/pendingdelivery",
    element: <PendingToDelivery />,
  },
  {
    path: "/runsheet/changedrunsheet",
    element: <ChangedRusheet />,
  },
  {
    path: "/runsheet/createdrunsheet",
    element: <CreateRunsheet />,
  },

  {
    path: "/runsheet/allrunsheet/gatepass",
    element: <GatePass />,
  },

  
  {
    path: "/booking/docketIssue",
    element: <DocketIssue />,
  },

  {
    path: "/test",
    element: <Test />,
  },
  // Reports
  {
    path: "/analytics/reports",
    element: <Report />,
  },
  {
    path: "/analytics/reports/MisDetailedReport",
    element: <MisDetailedReport />,
  },
  {
    path: "/analytics/reports/RunsheetReport",
    element: <RunsheetReport />,
  },
  {
    path: "/analytics/reports/ManifestReport",
    element: <ManifestReport />,
  },
];

export { auth_routes, routes };
